import React, { useEffect, useRef } from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Header from "../components/header"
import Footer from "../components/footer"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Logo from "../svgs/common/logo.svg"
import { Autoplay, EffectFade } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import ArticlePreview from "../components/article-preview"
import video from "../components/top.bg_video.mp4"
import Graf from "../svgs/top/graf.svg"
import WhiteLabel from "../svgs/top/whitelabel_img.svg"
import WhiteLabelSp from "../svgs/top/whitelabel_img_sp.svg"
import "swiper/css/effect-fade"
if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}
const IndexPage = ({ data }) => {
  const posts = data.allContentfulNewsPost.nodes
  const aboutLeftImgSliderRef = useRef(null)
  const aboutLeftTitleSliderRef = useRef(null)
  const aboutLeftTextSliderRef = useRef(null)
  const aboutRightImgSliderRef = useRef(null)
  const aboutRightTitleSliderRef = useRef(null)
  const aboutRightTextSliderRef = useRef(null)
  const startAutoplay = () => {
    if (isRunningAutoplay()) return
    aboutRightTextSliderRef.current?.swiper.autoplay.start()
  }
  const stopAutoplay = () => {
    if (!isRunningAutoplay()) return
    aboutRightTextSliderRef.current?.swiper.autoplay.stop()
  }
  const isRunningAutoplay = () => {
    if (aboutRightTextSliderRef.current?.swiper.autoplay.running) {
      return true
    } else {
      return false
    }
  }

  const resizeImgSlider = () => {
    aboutLeftImgSliderRef.current?.swiper.init()
    aboutLeftImgSliderRef.current?.swiper.update()
    aboutRightImgSliderRef.current?.swiper.init()
    aboutRightImgSliderRef.current?.swiper.update()
  }
  const adjustWrapperPosition = swiper => {
    const wrapper = swiper.$wrapperEl[0]
    const imgWrapperHeight = swiper.height
    const activeIndex = swiper.activeIndex
    let translateVal = -imgWrapperHeight * activeIndex
    wrapper.style.transform = "translate3d(0," + translateVal + "px,0)"
  }

  const imgSpeed = 800
  const textSpeed = 800
  const titleSpeed = 600
  const autoplayDelay = 1500
  const sliderLength = 3
  let isSliderInitialized = false
  const aboutLeftImgSliderParams = {
    initialSlide: sliderLength,
    slidesPerView: 1,
    direction: "vertical",
    speed: 0,
    loop: true,
    allowTouchMove: false,
    ref: aboutLeftImgSliderRef,
    virtualTranslate: true,
    onInit: swiper => {
      const el = swiper.$el[0]
      adjustWrapperPosition(swiper)
      setTimeout(() => {
        swiper.params.speed = imgSpeed
        el.classList.add("reverse")
      }, 100)
    },
    onSlideChange: swiper => {
      adjustWrapperPosition(swiper)
    },
    onResize: swiper => {
      adjustWrapperPosition(swiper)
    },
  }
  const aboutLeftTitleSliderParams = {
    modules: [EffectFade],
    initialSlide: sliderLength,
    slidesPerView: 1,
    speed: 0,
    loop: true,
    allowTouchMove: false,
    virtualTranslate: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    ref: aboutLeftTitleSliderRef,
    onInit: swiper => {
      setTimeout(() => {
        swiper.params.speed = titleSpeed
      }, 100)
    },
  }
  const aboutLeftTextSliderParams = {
    initialSlide: sliderLength,
    slidesPerView: 1,
    direction: "vertical",
    speed: 0,
    loop: true,
    allowTouchMove: false,
    ref: aboutLeftTextSliderRef,
    onInit: swiper => {
      setTimeout(() => {
        swiper.params.speed = textSpeed
      }, 100)
    },
  }
  const aboutRightImgSliderParams = {
    slidesPerView: 1,
    direction: "vertical",
    speed: 0,
    loop: true,
    allowTouchMove: false,
    ref: aboutRightImgSliderRef,
    onInit: swiper => {
      adjustWrapperPosition(swiper)
      setTimeout(() => {
        swiper.params.speed = imgSpeed
      }, 100)
    },
    onSlideChange: swiper => {
      adjustWrapperPosition(swiper)
    },
    onResize: swiper => {
      adjustWrapperPosition(swiper)
    },
  }
  const aboutRightTitleSliderParams = {
    modules: [EffectFade],
    slidesPerView: 1,
    speed: 0,
    loop: true,
    allowTouchMove: false,
    virtualTranslate: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    ref: aboutRightTitleSliderRef,
    onInit: swiper => {
      setTimeout(() => {
        swiper.params.speed = titleSpeed
        swiper.$wrapperEl[0].style.transitionDuration = titleSpeed + "ms"
      }, 100)
    },
  }
  const aboutRightTextSliderParams = {
    modules: [Autoplay],
    initialSlide: 0,
    slidesPerView: 1,
    direction: "vertical",
    speed: 0,
    loop: true,
    allowTouchMove: false,
    ref: aboutRightTextSliderRef,

    autoplay: {
      delay: autoplayDelay,
      disableOnInteraction: false,
    },

    onInit: swiper => {
      setTimeout(() => {
        swiper.params.speed = textSpeed
        isSliderInitialized = true
      }, 100)
    },

    onSlideNextTransitionStart: swiper => {
      if (!isSliderInitialized) return
      aboutRightImgSliderRef.current?.swiper.slideNext()

      const rightImgSliderActiveIndex =
        aboutRightImgSliderRef.current?.swiper.activeIndex
      aboutRightTitleSliderRef.current?.swiper.slideTo(
        rightImgSliderActiveIndex
      )

      aboutLeftImgSliderRef.current?.swiper.slidePrev()
      aboutLeftTextSliderRef.current?.swiper.slidePrev()

      const leftImgSliderActiveIndex =
        aboutLeftImgSliderRef.current?.swiper.activeIndex
      aboutLeftTitleSliderRef.current?.swiper.slideTo(leftImgSliderActiveIndex)
    },

    onSlidePrevTransitionStart: swiper => {
      if (!isSliderInitialized) return
      aboutRightImgSliderRef.current?.swiper.slidePrev()

      const rightImgSliderActiveIndex =
        aboutRightImgSliderRef.current?.swiper.activeIndex
      aboutRightTitleSliderRef.current?.swiper.slideTo(
        rightImgSliderActiveIndex
      )

      aboutLeftImgSliderRef.current?.swiper.slideNext()
      aboutLeftTextSliderRef.current?.swiper.slideNext()

      const leftImgSliderActiveIndex =
        aboutLeftImgSliderRef.current?.swiper.activeIndex
      aboutLeftTitleSliderRef.current?.swiper.slideTo(leftImgSliderActiveIndex)
    },
  }

  useEffect(() => {
    gsap.set(".section-top .layer02", {
      clipPath: "circle(0% at 100% 100%)",
    })
    gsap.to(".section-top .layer02", {
      clipPath: "circle(150% at 100% 100%)",
      duration: 1,
      scrollTrigger: {
        trigger: ".section-top",
        start: "15% top",
        end: "bottom bottom",
        toggleActions: "play none none reverse",
        ease: "power2.out",
        scrub: false,
        markers: false,
      },
    })
    var hideHeader = function () {
      const header = document.querySelector(".header")
      const wrap = document.querySelector(".section-message")
      header.classList.remove("is-hide")
      window.addEventListener("scroll", () => {
        let wrapPos = wrap.getBoundingClientRect().top
        if (wrapPos >= 0) {
          header.classList.remove("is-hide")
        } else {
          header.classList.add("is-hide")
        }
      })
      window.addEventListener("load", () => {
        let wrapPos = wrap.getBoundingClientRect().top
        if (wrapPos >= 0) {
          header.classList.remove("is-hide")
        } else {
          header.classList.add("is-hide")
        }
      })
    }
    hideHeader()
    const about03Slider = document.querySelector(".about03-slider")
    const options = {
      root: null,
      rootMargin: "0px 0px",
    }
    let about03SliderObserver = new IntersectionObserver(function (
      entries,
      observer
    ) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          startAutoplay()
          // about03SliderObserver.unobserve(entry.target);
        } else {
          stopAutoplay()
        }
      })
    },
    options)
    about03SliderObserver.observe(about03Slider)
    let resizeTimer
    window.addEventListener("resize", () => {
      clearTimeout(resizeTimer)

      if (isRunningAutoplay()) {
        stopAutoplay()
      }

      resizeTimer = setTimeout(() => {
        resizeImgSlider()
        startAutoplay()
        clearTimeout(resizeTimer)
      }, 1000)
    })
    const serviceAccordion = () => {
      const box = document.querySelector(".section-service")
      const btn = document.querySelector(".section-howto .box02 .btn")
      const inner = box.querySelector(".service-box")
      let innerHeight = inner.clientHeight
      const mql = window.matchMedia("(min-width: 769px)")
      if (!document.querySelector(".section-howto .box02 .btn")) return
      btn.addEventListener("click", function () {
        if (btn.classList.contains("is-active")) {
          box.classList.remove("is-open")
          btn.classList.remove("is-active")
          box.style.overflow = "hidden"
          box.style.maxHeight = null
        } else {
          box.classList.add("is-open")
          btn.classList.add("is-active")
          setTimeout(function () {
            box.style.overflow = "visible"
          }, 300)
          box.style.maxHeight = innerHeight + "px"
        }
      })
      mql.onchange = function (event) {
        innerHeight = inner.clientHeight
        if (box.classList.contains("is-open")) {
          box.style.maxHeight = innerHeight + "px"
        }
      }

      window.addEventListener(
        "resize",
        function (event) {
          innerHeight = inner.clientHeight
          if (box.classList.contains("is-open")) {
            box.style.maxHeight = innerHeight + "px"
          }
        },
        true
      )
    }
    serviceAccordion()

    const uaCheck = () => {
      var ua = window.navigator.userAgent.toLowerCase()
      const layer01 = document.querySelector(".layer01")
      if (ua.indexOf("android") !== -1) {
        return
      } else if (ua.indexOf("iphone") !== -1 || ua.indexOf("ipad") !== -1) {
        layer01.classList.add("webkit")
      } else if (
        ua.indexOf("mac os x") !== -1 &&
        ua.indexOf("safari") !== -1 &&
        ua.indexOf("chrome") == -1
      ) {
        layer01.classList.add("webkit")
      }
    }
    uaCheck()
  })
  return (
    <Layout headerCheck={true} page="top">
      <Seo
        title="becoz (ビコーズ) | 環境価値流通プラットフォーム"
        description="DATAFLUCT (データフラクト) が運営する becoz (ビコーズ) のブランドサイト。becoz はデータサイエンスの力で環境価値を見える化し、カーボンニュートラルに向けた行動を促進する環境価値流通プラットフォームです。"
      />
      <section className="section section-top">
        <div className="mv">
          <div className="layer02">
            <StaticImage
              src="../images/top/kv_pc.jpg"
              alt=""
              placeholder="blurred"
            />
          </div>
          <div className="layer01">
            <video preload="auto" playsInline={true} loop autoPlay muted>
              <source src={video} type="video/mp4" />
            </video>
            <div className="img-box">
              <StaticImage
                className="pc-only"
                src="../images/top/kv_bg_safari_pc.jpg"
                alt=""
                placeholder="blurred"
              />
              <StaticImage
                className="sp-only"
                src="../images/top/kv_bg_safari_sp.jpg"
                alt=""
                placeholder="blurred"
              />
            </div>
          </div>
          <div className="text-box">
            <div className="logo-box">
              <Logo />
            </div>
            <p className="title-main">
              “impact wallet” <br className="sp-only" />
              for all,
              <br />
              “impact wallet” <br className="sp-only" />
              for the future
            </p>
            <p className="text-main">
              生活者の意識を変え、行動を変え、
              <br />
              カーボンニュートラルな未来を
              <br className="sp-only" />
              ともにつくる
            </p>
            <div className="scroll-box">
              <div className="scroll"></div>
            </div>
          </div>
        </div>
      </section>
      <div className="main-wrap">
        <Header />
        <section className="section section-message">
          <div className="wrap">
            <div className="bg-box">
              <StaticImage
                className="pc-only"
                src="../images/top/message_bg_pc.jpg"
                alt=""
                placeholder="blurred"
              />
              <StaticImage
                className="sp-only"
                src="../images/top/message_bg_sp.jpg"
                alt=""
                placeholder="blurred"
              />
            </div>
            <div
              className="text-box anime_trigger base_anime"
              data-anim="is_fade"
            >
              <p className="title">Message</p>
              <p className="text">
                「becoz」はデータサイエンスの力でモノやサービスの環境価値を可視化、
                <br className="pc-only" />
                生活者を含むあらゆるステークホルダーの環境に配慮した行動を促進します。
                <br />
                <br />
                企業や行政、研究機関等との共創により、カーボンニュートラルな未来をつくる、
                <br className="pc-only" />
                環境価値流通プラットフォームを目指します。
              </p>
            </div>
          </div>
        </section>
        <section className="section section-economy">
          <div className="title-box">
            <p className="title">becoz economy</p>
          </div>
          <p className="text-main">個人から企業へ、企業から社会全体へ</p>
          <div className="img-box pc-only">
            <StaticImage
              src="../images/top/economy_graf.png"
              alt=""
              objectFit="cover"
              placeholder="blurred"
            />
          </div>
          <div className="img-box sp-only">
            <StaticImage
              src="../images/top/economy_graf_sp.png"
              alt=""
              objectFit="cover"
              placeholder="blurred"
            />
          </div>
        </section>
        <section className="section section-howto">
          <div className="box box01">
            <div className="title-box anim base_anim">
              <p className="title-main">How To</p>
              <p className="title-sub">法人のお客様へ</p>
              <p className="text-main">
                環境 x 生活者 x 新規事業「環境意識の高い生活者の行動変容」を
                <br className="pc-only" />
                軸に新規事業創出を目指すパートナーを募集
              </p>
            </div>
          </div>
          <div className="box box02">
            <div className="box-wrap">
              <div className="title-box">
                <p className="title-main anim base_anim">White Label Partner</p>
                <p className="title-sub anim base_anim">
                  生活者の行動変容を実現する新規事業の開発や、
                  <br className="pc-only" />
                  既存プロダクトへの機能追加を行いたい
                </p>
                <div className="img-box anim base_anim sp-only">
                  <WhiteLabelSp />
                </div>
                <p className="text-sub anim base_anim">
                  「becoz」の機能をカスタマイズし、ご希望に合わせて個別開発して提供します。
                </p>
                <p className="text-main anim base_anim">
                  <span>
                    自社の抱える個人顧客向けに、気候変動や環境にまつわるサービスを提供したい
                  </span>
                  <span>
                    自社サービス、アプリにCO2排出量の可視化、オフセット機能を導入したい
                  </span>
                  <span>
                    その他、業界、企業特性に合わせた導入方法を提案いたします
                  </span>
                </p>
              </div>
              <div className="img-box anim base_anim pc-only">
                <WhiteLabel />
              </div>
              <div className="btn-box anim base_anim">
                <div className="btn">
                  詳細を見る<span></span>
                </div>
              </div>
            </div>
          </div>
          <section className="section section-service">
            <div className="bg-box">
              <StaticImage
                alt=""
                className="pc-only"
                src="../images/top/service_bg.jpg"
                placeholder="blurred"
              />
              <StaticImage
                alt=""
                className="sp-only"
                src="../images/top/service_bg.sp.jpg"
                placeholder="blurred"
              />
            </div>
            <div className="service-box">
              <div className="list-wrap">
                <div className="item anim base_anim">
                  <div className="icon">
                    <StaticImage
                      src="../images/top/service_icon_04.png"
                      alt=""
                      placeholder="blurred"
                    />
                  </div>
                  <div className="box">
                    <div className="title">
                      <p className="title-sub">becoz scope</p>
                      <p className="title-main">環境について学ぶ・発信する</p>
                    </div>
                    <div className="text">
                      <p className="text-main">
                        becozのオウンドメディアで、データに基づいた環境にまつわるコンテンツを学んだり、発信したりすることができます。
                      </p>
                      <p className="text-sub">
                        例 : 上場企業のscope3開示状況に関するコンテンツなど
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item anim base_anim">
                  <div className="icon">
                    <StaticImage
                      src="../images/top/service_icon_02.png"
                      alt=""
                      placeholder="blurred"
                    />
                  </div>
                  <div className="box">
                    <div className="title">
                      <p className="title-sub">becoz score / becoz wallet</p>
                      <p className="title-main">生活者の環境負荷を可視化する</p>
                    </div>
                    <div className="text">
                      <p className="text-main">
                        様々なデータから個人のCO2排出量などを計算し、自分の資産のように環境負荷・価値を可視化・管理することができます。
                      </p>
                      <p className="text-sub">
                        例 :
                        クレジットカードの利用明細を連携することで、自身のCO2排出量がわかる
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item anim base_anim">
                  <div className="icon">
                    <StaticImage
                      src="../images/top/service_icon_05.png"
                      alt=""
                      placeholder="blurred"
                    />
                  </div>
                  <div className="box">
                    <div className="title">
                      <p className="title-sub">becoz challenge / becoz point</p>
                      <p className="title-main">詳細を見る</p>
                    </div>
                    <div className="text">
                      <p className="text-main">
                        環境に配慮した行動にポイントを付与することで、個人の環境に良い行動を奨励・増加させます。
                      </p>
                      <p className="text-sub">
                        例 : 節電やリサイクル貢献行動によるポイント獲得
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item anim base_anim">
                  <div className="icon">
                    <StaticImage
                      src="../images/top/service_icon_06.png"
                      alt=""
                      placeholder="blurred"
                    />
                  </div>
                  <div className="box">
                    <div className="title">
                      <p className="title-sub">becoz market</p>
                      <p className="title-main">生活者の環境価値を流通させる</p>
                    </div>
                    <div className="text">
                      <p className="text-main">
                        削減したCO2排出量をクレジットという価値に変換・保有して、様々な取引に利用することができます。
                      </p>
                      <p className="text-sub">例 : 太陽光パネルのデータ収集</p>
                    </div>
                  </div>
                </div>
                <div className="item anim base_anim">
                  <div className="icon">
                    <StaticImage
                      src="../images/top/service_icon_01.png"
                      alt=""
                      placeholder="blurred"
                    />
                  </div>
                  <div className="box">
                    <div className="title">
                      <p className="title-sub">becoz credit / becoz offset</p>
                      <p className="title-main">環境負荷をオフセットする</p>
                    </div>
                    <div className="text">
                      <p className="text-main">
                        イベントや買い物などの経済活動におけるCO2排出量を算出し、その量に対応するCO2削減活動に投資をすることで、排出されるCO2を埋め合わせることができます。
                      </p>
                      <p className="text-sub">
                        例 : スポーツチームの試合のオフセット
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="box box03">
            <div className="wrap">
              <div className="item">
                <p className="title-main anim base_anim">Platform Partner</p>
                <p className="title-sub anim base_anim">
                  生活者の環境貢献や、
                  <br />
                  行動変容に関する実証を行いたい
                </p>
                <p className="text-sub anim base_anim">
                  「becoz」のサービスを活用した実証実験や、
                  <br />
                  地域を限定した本格導入などを提案いたします。
                </p>
                <p className="text-main anim base_anim">
                  <span>
                    地域住民や学校法人を対象とした行動変容サービスを提供
                  </span>
                  <span>地域通貨等との連携アイデアを提案</span>
                  <span>
                    イベントや勉強会等の、エデュケーションにつながる企画も実施可能
                  </span>
                </p>
              </div>
              <div className="item">
                <p className="title-main anim base_anim">Engagement Partner</p>
                <p className="title-sub anim base_anim">
                  生活者に環境貢献につながるモノ・サービスや、
                  <br className="pc-only" />
                  新しいオフセットの手段を提供したい
                </p>
                <p className="text-sub anim base_anim">
                  「becoz」のプラットフォームやマーケティング連携にて、
                  <br className="pc-only" />
                  “環境先進層”に自社製品・サービスを訴求できます。
                </p>
                <p className="text-main anim base_anim">
                  <span>
                    SNSでのマーケティング連携から、本格的なイベント企画まで、幅広いオプションを提供
                  </span>
                  <span>
                    生活者がカーボンオフセットを行える数少ないサービスの一つである「becoz」で、さまざまなオフセットを実現
                  </span>
                  <span>ポイント事業者やEC事業者との将来的な連携も構想</span>
                </p>
              </div>
            </div>
            <div className="link-wrap anim base_anim">
              <div className="line"></div>
              <p className="text-main">Be our partners</p>
              <Link
                to="https://datafluct-21791923.hs-sites.com/becoz_toiawase"
                target="_blank"
                className="btn"
              >
                お問い合わせ
              </Link>
            </div>
          </div>
        </section>
        <section className="section section-about">
          <div className="title-box anim base_anim">
            <p className="title-main">About Us</p>
            <p className="title-sub">becozとは</p>
          </div>
          <div className="box box01">
            <div className="main-wrap">
              <div className="text-box">
                <p className="title-main anim base_anim">
                  <span className="num">01</span>
                  <span className="catch">
                    <span className="underline">CO2排出量</span>が見える
                  </span>
                </p>
                <p className="title-sub anim base_anim">
                  「becoz」は日々の行動によって生まれるCO2排出量を、
                  <br className="pc-only" />
                  外部データ連携やアンケートによって可視化・定量化することができます。
                </p>
                <ul className="text-main anim base_anim">
                  <li className="item">
                    ユーザの入力データやクレジットカードの決済データから可視化、住宅データや移動データの連携も構想
                  </li>
                  <li className="item">
                    個人の総排出量、削減量・オフセット量を可視化し削減目標との乖離を管理
                  </li>
                  <li className="item">
                    グリーンバリューチェーンプラットフォームが提供する「排出源単位データベース」をもとにCO2排出量を算出
                  </li>
                </ul>
              </div>
              <div className="img-box anim base_anim">
                <Graf />
              </div>
            </div>
            <div className="slider-wrap anim" data-anim="is_slideIn">
              <Swiper
                modules={[Autoplay]}
                spaceBetween={10}
                slidesPerView={1.206}
                breakpoints={{
                  768: {
                    spaceBetween: 60,
                    slidesPerView: 3.64,
                  },
                }}
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: false,
                }}
                loop={true}
              >
                <SwiperSlide className="slide01">
                  <div className="slide-inner">
                    <div className="wrap">
                      <p className="usage">Laptop (8hrs of use)</p>
                      <p className="value">+0.28</p>
                      <p className="unit">kg CO2eq</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="slide02">
                  <div className="slide-inner">
                    <div className="wrap">
                      <p className="usage">Steak</p>
                      <p className="value">+12.15</p>
                      <p className="unit">kg CO2eq</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="slide03">
                  <div className="slide-inner">
                    <div className="wrap">
                      <p className="usage">Train</p>
                      <p className="value">+5.55</p>
                      <p className="unit">kg CO2eq</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="slide04">
                  <div className="slide-inner">
                    <div className="wrap">
                      <p className="usage">Laptop (Purchase)</p>
                      <p className="value">+452</p>
                      <p className="unit">kg CO2eq</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="slide05">
                  <div className="slide-inner">
                    <div className="wrap">
                      <p className="usage">Coffee</p>
                      <p className="value">+0.14</p>
                      <p className="unit">kg CO2eq</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="slide06">
                  <div className="slide-inner">
                    <div className="wrap">
                      <p className="usage">Clothes</p>
                      <p className="value">+11.98</p>
                      <p className="unit">kg CO2eq</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="slide07">
                  <div className="slide-inner">
                    <div className="wrap">
                      <p className="usage">Books</p>
                      <p className="value">+5.02</p>
                      <p className="unit">kg CO2eq</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="slide08">
                  <div className="slide-inner">
                    <div className="wrap">
                      <p className="usage">Bicycle</p>
                      <p className="value">-1.07</p>
                      <p className="unit">kg CO2eq</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="slide09">
                  <div className="slide-inner">
                    <div className="wrap">
                      <p className="usage">Renewable Energy</p>
                      <p className="value">-110</p>
                      <p className="unit">kg CO2eq</p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="box box02">
            <div className="main-wrap">
              <div className="img-box  anim base_anim">
                <StaticImage
                  src="../images/top/img_about_02.png"
                  alt=""
                  objectFit="contain"
                  placeholder="blurred"
                />
              </div>
              <div className="text-box  anim base_anim">
                <p className="title-main">
                  <span className="num">02</span>
                  <span className="catch">
                    <span className="underline">オフセット</span>プランを
                    <br className="sp-only" />
                    選択できる
                  </span>
                </p>
                <p className="title-sub">
                  「becoz」ではCO2の削減量、削減方法の組み合わせで、
                  <br className="pc-only" />
                  自身のライフスタイルに合ったオフセットプランを選択できます。
                </p>
                <ul className="text-main">
                  <li className="item">
                    1.5°C目標達成、個人のカーボンニュートラル達成など削減したい量から、オフセットプランの選択が可能
                  </li>
                  <li className="item">
                    森林、省エネ、再エネなどオフセットをするためのカーボンクレジットを選択可能
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="box box03">
            <div className="card-wrap  anim base_anim about03-slider">
              <div className="box">
                <div className="image-box">
                  <Swiper {...aboutLeftImgSliderParams}>
                    <SwiperSlide>
                      <StaticImage
                        src="../images/top/img_about_03_01_01.jpg"
                        alt=""
                        objectFit="cover"
                        placeholder="blurred"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <StaticImage
                        src="../images/top/img_about_03_01_02.jpg"
                        alt=""
                        objectFit="cover"
                        placeholder="blurred"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <StaticImage
                        src="../images/top/img_about_03_01_03.jpg"
                        alt=""
                        objectFit="cover"
                        placeholder="blurred"
                      />
                    </SwiperSlide>
                  </Swiper>
                </div>
                <div className="text-box">
                  <div className="title-main">
                    <Swiper {...aboutLeftTitleSliderParams}>
                      <SwiperSlide>Bicycle</SwiperSlide>
                      <SwiperSlide>Renewable Energy</SwiperSlide>
                      <SwiperSlide>Beans</SwiperSlide>
                    </Swiper>
                  </div>
                  <div className="num-main">
                    <Swiper {...aboutLeftTextSliderParams}>
                      <SwiperSlide>
                        -1.0
                        <span className="last">7</span>
                      </SwiperSlide>
                      <SwiperSlide>
                        -11
                        <span className="last">0</span>
                      </SwiperSlide>
                      <SwiperSlide>
                        -1.1
                        <span className="last">0</span>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                  <p className="unit">kg CO2eq</p>
                </div>
              </div>
              <div className="box">
                <div className="image-box">
                  <Swiper {...aboutRightImgSliderParams}>
                    <SwiperSlide>
                      <StaticImage
                        src="../images/top/img_about_03_02_01.jpg"
                        alt=""
                        objectFit="cover"
                        placeholder="blurred"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <StaticImage
                        src="../images/top/img_about_03_02_03.jpg"
                        alt=""
                        objectFit="cover"
                        placeholder="blurred"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <StaticImage
                        src="../images/top/img_about_03_02_02.jpg"
                        alt=""
                        objectFit="cover"
                        placeholder="blurred"
                      />
                    </SwiperSlide>
                  </Swiper>
                </div>
                <div className="text-box">
                  <div className="title-main">
                    <Swiper {...aboutRightTitleSliderParams}>
                      <SwiperSlide>Automobile</SwiperSlide>
                      <SwiperSlide>Dairy</SwiperSlide>
                      <SwiperSlide>Electricity</SwiperSlide>
                    </Swiper>
                  </div>
                  <div className="num-main">
                    <Swiper {...aboutRightTextSliderParams}>
                      <SwiperSlide>
                        +10.7
                        <span className="last">0</span>
                      </SwiperSlide>
                      <SwiperSlide>
                        +1.4
                        <span className="last">6</span>
                      </SwiperSlide>
                      <SwiperSlide>
                        +11
                        <span className="last">0</span>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                  <p className="unit">kg CO2eq</p>
                </div>
              </div>
            </div>
            <div className="main-wrap  anim base_anim">
              <div className="text-box">
                <p className="title-main">
                  <span className="num">03</span>
                  <span className="catch">
                    <span className="underline">環境に配慮した行動</span>
                    を選択できる
                  </span>
                </p>
                <p className="title-sub">
                  「becoz」はCO2排出量の少ないモノやサービスを提案し、
                  <br className="pc-only" />
                  個人の環境負荷の低いライフスタイル選択をサポートします。
                </p>
              </div>
            </div>
            <div className="link-wrap  anim base_anim">
              <div className="line"></div>
              <p className="text-main">Find out more about becoz</p>
              <a href="/about" className="btn">
                わたしたちについて
              </a>
            </div>
            <div className="annotation-box  anim base_anim">
              各排出量は、環境省と経済産業省が公開している「グリーン・バリューチェーンプラットフォーム」内に記載されている「排出原単位データベース」および3EIDと、総務省統計局の「家計調査」をもとに概算しております。なお、自転車によるCO2削減量は、5kmをガソリン車で移動した場合と比較して削減できる排出量を記載しております。
            </div>
          </div>
        </section>
        <section className="section section-app">
          <div className="text-box anim base_anim">
            <p className="title-sub">個人向けアプリ「becoz」の紹介</p>
            <div className="box">
              <div className="wrap">
                <p className="title-main">
                  <span className="en">becoz wallet</span>で<br />
                  カーボンニュートラルを
                  <br className="sp-only" />
                  日常に
                </p>
              </div>
              <div className="wrap">
                <p className="text-main">
                  「becoz
                  wallet」は、従来では捉えきれなかった、生活にまつわるCO2排出量削減アクションを様々なデータを元に可視化します。削減しきれなかったCO2排出量を、排出量に見合った温室効果ガスの削減活動に投資する「カーボン・オフセット」を行います。これから先の未来を生きるなかで当たり前になる暮らしを、becoz
                  walletは提供します。
                </p>
              </div>
            </div>
          </div>
          <div className="card-box">
            <Link
              to="https://wallet.becoz.ai"
              target="_blank"
              className="item anim"
              data-anim="is_slideIn"
            >
              <span className="title-main">wallet</span>
              <span className="title-sub">
                CO2排出削減量を
                <span>管理する</span>
              </span>
              <span className="text-main">
                個人の総排出量・削減
                量・オフセット量・をポートフォリオ化削減目標との乖離を管理
              </span>
              <StaticImage
                src="../images/top/img_app_01.png"
                placeholder="blurred"
                alt=""
              />
            </Link>
            <Link
              to="https://www.saisoncard.co.jp/lp/becoz/"
              target="_blank"
              className="item anim"
              data-anim="is_slideIn"
            >
              <span className="title-main">card</span>
              <span className="title-sub">
                消費者・企業の購買データを記録
                <span>見える化する</span>
              </span>
              <span className="text-main">
                利用明細データをbecoz
                walletに連携、購買データからCO2排出量を可視化できるカーボンニュートラル視点のクレジットカード
              </span>
              <StaticImage
                src="../images/top/img_app_02.png"
                placeholder="blurred"
                alt=""
              />
            </Link>
          </div>
        </section>
        <section className="section section-news">
          <div className="title-box">
            <p className="title-main">News</p>
          </div>
          <div className="article-box">
            <ArticlePreview className="article-wrap" posts={posts} />
            <Link to="/news/" className="btn anim base_anim">
              ニュース一覧
            </Link>
          </div>
        </section>
        <Footer />
      </div>
    </Layout>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    allContentfulNewsPost(
      filter: { node_locale: { eq: "ja" } }
      sort: { fields: [publishDate], order: DESC }
      limit: 3
    ) {
      nodes {
        title
        slug
        publishDate(formatString: "YYYY.MM.DD")
        keyVisual {
          url
          gatsbyImageData
        }
        metadata {
          tags {
            contentful_id
            name
          }
        }
      }
    }
  }
`
