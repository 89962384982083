import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage , StaticImage} from "gatsby-plugin-image"
import * as styles from "./article-preview.module.scss"

const ArticlePreview = ({ posts , featured }) => {
  if (!posts) return null
  if (!Array.isArray(posts)) return null
  return (
    <ul className={`${styles.articleList} ${featured ? styles.articleListFeatured : ""}`}>
      {posts.map(post => {
        let tags = post.metadata.tags;
        let keyVisual = false
        if(post.keyVisual){
          keyVisual = true
        }
        return (
          <li key={post.slug} className={`anim base_anim ${styles.item}`}>
            <Link className={styles.link} to={`/news/${post.slug}`}>
              <div className={styles.imgBox}>
                {keyVisual
                  ? <GatsbyImage image={getImage(post.keyVisual.gatsbyImageData)} alt={post.title}/>
                  : <StaticImage src="../images/news-list/kv_default.jpg" alt={post.title}/>
                }
              </div>
              <div className={styles.textBox}>
                {featured && <p className={styles.featuredTag}>Featured</p>}
                <h2 className={styles.description}>{post.title}</h2>
                <div className={styles.tags}>
                  {tags && tags.map(tag => {
                    return <Link key={tag} className="tag" to={"/news/tag/" + tag.contentful_id}>#{tag.name}</Link>
                  })}
                </div>
                <div className={styles.date}>
                  <p>{post.publishDate}</p>
                </div>
              </div>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default ArticlePreview
