// extracted by mini-css-extract-plugin
export var articleList = "article-preview-module--articleList--e9c20";
export var articleListFeatured = "article-preview-module--articleListFeatured--cd802";
export var date = "article-preview-module--date--a98fa";
export var description = "article-preview-module--description--92cd6";
export var featuredTag = "article-preview-module--featured-tag--9805a";
export var imgBox = "article-preview-module--imgBox--2c756";
export var item = "article-preview-module--item--a73f4";
export var link = "article-preview-module--link--7c0ac";
export var tags = "article-preview-module--tags--8c20f";
export var textBox = "article-preview-module--textBox--4afc1";